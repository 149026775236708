.AddPointEventForm {
  .add {
    color: #568b6b;
  }
  .remove {
    color: #c56060;
  }

  .AmountInput {
    width: 30px;
  }
}
