.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  width: 100%;
}

tbody:focus {
  outline: 1px solid #568b6b;
}

.inlinePanel {
  display: inline-flex !important;
}

.inlinePanel button {
  margin-left: 5px;
}
