.order-status-card {
  display: grid;
  grid-template-columns: auto 1fr;
  white-space: nowrap;
}

.order-status-card .order-status-icon {
  width: 4em;
  height: 4em;
  background-color: #568b6b;
  border-radius: 50%;
  color: white;
  transition: 0.4s;
}
