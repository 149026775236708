$html_font_size: 12px;

$valid: #568b6b;
$warning: #c58860;
$error: #c56060;
$edit: #c58860;

$light: #eeeeee;
$medium: #cccccc;
$heavy: #aaaaaa;
