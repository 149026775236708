.orders-layout {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.print-facture-modal {
  width: 500px;
  box-sizing: border-box;
}
