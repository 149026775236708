.draft-editor {
  display: grid;
  grid-template-columns: minmax(min-content, 2fr) 5fr;
  overflow: hidden;
  color: #393939;
}

.draft-info-panel {
  display: grid;
  grid-template-rows: auto auto auto 1fr auto;
  grid-row-gap: 0.3rem;
  overflow: hidden;
}

.draft-info-panel,
.composition-editor {
  & > * {
    border-radius: 0.5rem;
    border: 1px solid #e8e8e8;
    background-color: white;
    padding: 0.2rem;
  }
}

.draft-editor input:focus {
  outline: none;
}

.flex-text {
  font-weight: lighter;
  font-size: 2em;
}

.draft-content {
  position: relative;
  max-height: 100%;
  height: 100%;
  overflow: hidden;
}

.composition-editor {
  max-height: 100%;
  max-width: 900px;
  padding: 0.5em;
  display: grid;
  grid-template-rows: 0fr 1fr;
  grid-row-gap: 0.5em;
  overflow: hidden;
  box-sizing: border-box;
}

.composition-editor-box {
  max-height: 100%;
  overflow: hidden;
}

.composition-editor-content {
  max-height: 100%;
  overflow: hidden;
  padding: 0;
}

.composition-list {
  background-color: transparent;
  border: none;
  overflow: hidden;
  max-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;

  &_header {
    border-radius: 50px;
    background-color: white;
  }

  &_content {
    overflow-y: auto;
    table {
      border-collapse: separate;
      border-spacing: 0 0.2rem;

      td {
        &:first-child {
          border-top-left-radius: 4rem;
          border-bottom-left-radius: 4rem;
        }

        &:last-child {
          border-top-right-radius: 4rem;
          border-bottom-right-radius: 4rem;
        }

        transition: 0.4s;
        background-color: white;
        border: none;
      }

      .composition-card {
        &.selected {
          td {
            background-color: #568b6ba6 !important;
            color: white;
          }
        }

        &:focus,
        &:hover {
          outline: none;
          td {
            background-color: #f4f4f4;
          }
          cursor: pointer;
        }
      }
    }
  }
}
.composition-list .formes-list {
  position: fixed;
  margin-top: -2em;
  margin-left: 3em;
}

.composition-ingredient-list {
  overflow-y: auto;
  max-height: 100%;
  box-sizing: border-box;
  ::-webkit-scrollbar-thumb {
    background-color: grey !important;
  }
}

.composition-ingredient-list .suggestion-row input {
  max-width: 100% !important;
  height: 100%;
}

.calcInput {
  padding: 0.2em;
  border: 1px solid lightgray;
  border-radius: 25px;
  font-size: 1.2em;

  .material-icons {
    font-size: 1rem;
  }

  input {
    font-weight: 100;
    border-radius: 25px;
    padding: 0 0.5em;
    text-align: right;
    background-color: transparent !important;
    border-bottom: none !important;
  }
}

.calcInput input:disabled {
  cursor: default;
}

.locked .text-input-row {
  font-weight: 100;
  border-radius: 25px;
  padding: 0 0.5em;
  width: 4em;
  background-color: #959595;
  border-bottom: none !important;
}

.calcInput .text-input-row input:focus {
  border-color: transparent;
}

.calcInput .text-input-row .input-prefix {
  padding: 0 0.2em;
  font-size: 0.6em;
  font-weight: bold;
  color: grey;
}

.ingredients-parser {
  background-color: rgba(255, 255, 255, 0.5);
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  min-height: 100%;

  .parser-area {
    max-height: 100%;
    overflow-y: auto;
    font-size: 1rem;

    textarea {
      max-height: 100%;
      font-size: 1rem;
    }
  }
}

.formes-list {
  z-index: 99;
  button {
    border: none;
    width: 100%;
    color: gray;
    justify-content: flex-start;
    background-color: white;
    padding: 5px 10px;
    border-bottom: 1px solid lightgrey;

    &:hover {
      background-color: #c5c5c5;
      cursor: pointer;
    }

    &:focus {
      background-color: #c5c5c5;
      color: black;
    }
  }
}

.shipping-panel {
  select {
    border: 1px solid #c4c4c4;
    border-radius: 0.2em;
    color: #6f6f6f;
  }
  select::after {
    border-left: 1px solid red;
    color: red;
  }

  input {
    padding: 0.2em;
    border: 1px solid lightgray;
    border-radius: 25px;
    font-size: 1.2em;
  }
}

.payment-panel {
  &_content {
    color: #686868;
    .active {
      color: #568b6b;
    }

    .circle {
      min-width: 0.8rem;
      width: 0.8rem;
      min-height: 0.8rem;
      height: 0.8rem;
      margin: 0.2rem;
    }
  }

  input {
    height: 13px;
  }
}

.draft-confirmation-panel {
  display: grid;
  grid-template-rows: auto auto;
  background-color: transparent;
  border: none;

  button {
    font-size: 0.8vmax;
  }
}

/** PICKER */

.picker {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(101, 101, 101, 0.5);
  z-index: 9999;
  padding: 1em 0;
  border: none;
  border-radius: 0;

  &_container {
    &.client,
    &.practitioner {
      min-width: 500px;
      max-width: 800px;
    }

    &.client.picked {
      min-width: 500px;
      max-width: 90%;
    }

    margin: auto;
    max-height: calc(100% - 4em);
    overflow: hidden;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-row-gap: 1rem;
  }

  &_content {
    overflow-y: auto;
    overflow-x: hidden;

    .card {
      background-color: white;
      border-radius: 0.5rem;
      border: none;
    }

    form {
      padding: 0.5rem;
    }
  }

  &_header {
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-column-gap: 1rem;

    &_search {
      border-radius: 50px;
      padding: 0 0.5rem;

      & .search-field {
        height: 100%;

        input {
          font-size: 1rem;
        }
      }
    }
  }

  & .return-button {
    height: 100%;
    padding: 0 0.5rem;
    border-radius: 50px;

    &:focus,
    &:hover {
      background-color: #c4c4c4;
    }
  }

  .add-button,
  .close-button {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
  }
}

/** PICKER TYPE PANEL */

.picker-type-panel {
  label {
    display: none;
  }

  button {
    transition: 0.2s;
    &:focus,
    &:hover,
    &.active {
      label {
        display: contents;
        cursor: pointer;
      }
    }
  }
}

/** DRAFT HEADER */
.weight-picker {
  align-items: flex-end;
  .operator {
    padding: 0.2rem 0.4rem;
  }

  label {
    font-weight: bold;
    color: black;
  }
}

.weight-panel {
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: auto 1fr;

  .operator {
    padding: 0.2rem 0.4rem;
  }

  label {
    font-size: 0.6em;
    color: black;
    font-weight: bold;
  }

  > div:focus-within {
    background-color: #f4f4f4 !important;
  }
}

.libelle-input,
.destinataire-input {
  &:focus-within {
    .material-icons {
      color: #568b6b;
    }
  }

  input {
    border: 1px solid lightgray;
    border-radius: 25px;
    transition: 0.4s;
    padding: 0.4em;

    &:focus {
      background-color: #f4f4f4 !important;
    }
  }
}

.delete-composition-button {
  position: relative;
  background-color: white;

  > div {
    padding: 0.4rem;
    position: absolute;
    background-color: white;
    left: -100px;
    top: 1rem;

    button:focus {
      text-decoration: underline;
    }
  }

  > button {
    border-radius: 50%;

    * {
      font-size: 1.5rem !important;
      color: #686868 !important;
    }
  }
}

.draft-comment,
.composition-commentaire-field {
  textarea {
    font-size: 0.8em;
  }
}

/** */

textarea {
  border-color: lightgray;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

textarea:focus {
  outline: none;
  background-color: #f4f4f4;
  transition: 0.2s;
}

.add-button {
  background-color: #568b6b;
  color: white;
}

.validate-button {
  padding: 0.4rem;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  background-color: transparent;
  color: #568b6b;
  transition: 0.4s;
  &:focus,
  &:hover {
    border-color: #568b6b !important;
    background-color: transparent !important;
  }
}

.parser-transition-enter {
  max-width: 0px;
  transform: translateX(100%);
  background-color: white;
}

.parser-transition-enter-done {
  border-left: 1px solid #cbcbcb;
  max-width: 300px;
  position: absolute;
  right: 0;
  height: 100%;
  background-color: white;
  z-index: 99;
  transition: all 200ms ease-in;
}

.parser-transition-exit {
  border-left: 1px solid #cbcbcb;
  width: 66vw;
  max-width: 600px;
  position: absolute;
  right: 0;
  height: 100%;
  background-color: white;
  z-index: 99;
}

/* fires as element leaves the DOM*/
.parser-transition-exit-done {
  max-width: 0px;
  transform: translateX(100%);
  transition: all 400ms ease-in;
}
