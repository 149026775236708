.composition-ingredient-list {
  table thead tr {
    background-color: transparent;
  }
}

.composition-ingredient-list table tr:last-child td {
  border-bottom: 1px solid #cbcbcb;
}

.composition-ingredient-list tbody td:first-child input {
  width: 100px;
}

.composition-ingredient-list td.unlockable:hover {
  background-color: white !important;
  cursor: pointer;
}

.composition-ingredient-list th {
  border-top: 1px solid #cbcbcb;
  background-color: white;
  white-space: nowrap;
  text-align: center;
}

.composition-ingredient-list th i {
  color: grey;
  font-size: 16px;
}

.composition-ingredient-list td {
  font-weight: normal;
  cursor: initial;
  color: inherit;
  text-align: right;
  height: 16px;
  padding: 0 0.2rem;
}

.composition-ingredient-list td,
.composition-ingredient-list th {
  border-left: 1px solid #cbcbcb;
  border-right: 1px solid #cbcbcb;
}

.composition-ingredient-list tr:nth-child(1n) .locked,
.composition-ingredient-list tr:nth-child(1n) .unlockable {
  background-color: #f8f8f8;
}

.composition-ingredient-list tr:nth-child(2n) .locked,
.composition-ingredient-list tr:nth-child(2n) .unlockable {
  background-color: #f4f4f4;
}

.composition-ingredient-list {
  td {
    input[type='number'],
    input[type='text'] {
      &:focus {
        color: black;
      }

      background-color: transparent;
      border-color: transparent !important;
      height: 1em;
      min-width: 100%;
      font-weight: normal;
      font-size: 1em;
    }
  }
}

.composition-ingredient-list td.locked {
  cursor: default;
}

.composition-ingredient-list tr.locked {
  background-color: white;
}

.composition-ingredient-list td:last-child.locked {
  width: 1em;
}

.composition-ingredient-list .text-input-row.selected {
  background-color: transparent;
}

.composition-ingredient-list input:hover,
.composition-ingredient-list input:focus {
  outline: none;
  background-color: #e4e4e4;
}

.composition-ingredient-list .search-row {
  background-color: #e5e5e5;
}

.composition-ingredient-list .search-row button,
.composition-ingredient-list .search-row > div {
  padding: 0.2em;
  font-size: 1em;
  background-color: transparent;
  color: #6f6f6f;
  border-radius: 3px;
}

.composition-ingredient-list .search-row button:disabled {
  padding: 0.2em;
  color: #c4c4c4;
}

.composition-ingredient-list .search-row button:focus {
  color: #568b6b;
}
