.container {
  max-width: 250px;
  height: 100%;
}

.header {
  background: inherit;
  height: 60px;
}

.pointEventListWrapper {
  max-height: 190px;
  overflow: auto;
}

.pointEventList {
  height: 100%;
}

.footer {
  background: inherit;
}
