.client-page {
  display: grid;
  grid-template-columns: minmax(500px, 1fr) 3fr;
  height: 100%;

  &_info {
    display: grid;
    grid-template-rows: repeat(autofill, auto);
    row-gap: 0.5rem;
    box-sizing: border-box;
  }

  &_order {
    height: 100%;
    max-height: 100%;
    overflow: hidden;
  }
}
