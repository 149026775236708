.text-input.error label {
  color: red !important;
}

.text-input.error input {
  color: #e24949 !important;
  border-color: #e24949 !important;
}

.number-input_row,
.text-input_row {
  display: flex;
  align-items: center;
  width: 100%;
}

.text-input {
  &.suffix,
  &.prefix {
    .text-input_row {
      display: grid;
      grid-column-gap: 0.2rem;
    }
  }

  &.prefix.suffix {
    grid-template-columns: auto 1fr auto;
  }

  &:not(.prefix).suffix {
    grid-template-columns: auto 1fr;
  }

  &:not(.suffix).prefix {
    grid-template-columns: 1fr auto;
  }
}

.text-input-row_prefix {
}
