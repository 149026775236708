.orders-filters input {
  background-color: transparent;
}

.orders-filters .text-input-row .input-prefix {
  font-size: 0.8em;
  margin-right: 0.2em;
}

.orders-filters .text-input-row button,
.orders-filters button.delete-filters,
.orders-filters .date-input-row button {
  background-color: transparent;
  padding: 0;
  border-radius: 50%;
}

.orders-filters button:hover,
.orders-filters button:focus {
  background-color: lightgray;
}

.orders-filters .text-input-row.selected,
.orders-filters .date-input-row.selected {
  background-color: #e8e7e7;
}

.orders-filters {
  border-bottom: 1px solid #cbcbcb;
  .search-bar {
    transition: 0.2s;
    font-size: 1.15rem;
    border-radius: 0;

    &:focus-within {
      background-color: #e9e9e9;
      border-radius: 0.4rem;
    }
  }

  .text-selector {
    display: flex;
    flex-direction: column;

    select {
      font-size: 1.15rem;
      border: 1px solid #e8e8e8;
      background-color: white;
      border-radius: 0.4rem;
      padding: 0rem;
      height: 1.5rem;
      color: #686868;
      &.active {
      }
    }
  }

  .button-lgrey.take {
    border-color: currentColor !important;
    background-color: white !important;
  }

  .active {
    * {
      border-color: #6c6c6c !important;
    }

    label,
    input {
      color: #6c6c6c !important;
    }
  }

  .text-input-row,
  .number-input_row,
  .date-input-row {
    border: 1px solid #e8e8e8;
    background-color: white;
    border-radius: 0.4rem;
    transition: 0.2s;
    font-size: 1.15rem;
    height: 1.5rem;

    &:focus-within {
      background-color: #d9d9d9;
    }

    input {
      color: #807f7f;
      text-align: right;
      outline: none;
      &::placeholder {
        color: #9e9d9d;
      }
    }

    button {
      background-color: transparent;
      padding: 0;
      border-radius: 50%;
      &:hover,
      &:focus {
        background-color: lightgray;
      }
    }
  }
}
