input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}

input[type='checkbox'] {
  border-radius: 2px;
  height: 1.4em;
  width: 1.4em;
  background: #fff;
  border: 1px solid #ccc;
}

input[type='checkbox']:checked {
  background: #f5f5f5;
  position: relative;
  border-color: #c5c5c5;
}

input[type='checkbox']:checked::before {
  border-radius: 0.1em;
  background-color: #c5c5c5;
  content: '';
  width: 0.6em;
  height: 0.6em;
  display: block;
  font-size: 1.4em;
  position: absolute;
}
