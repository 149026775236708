.client-picker .box {
  max-width: calc(100vw - 1em);
}

.client-selected {
  .client-selected_content {
    display: grid;
    grid-row-gap: 1em;

    > div {
      display: grid;
      grid-row-gap: 1rem;
      grid-template-rows: fit-content(0) fit-content(0);
    }
  }
}

@media screen and (min-width: 1080px) {
  .picked {
    .client-selected {
      .client-selected_content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1em;
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .picked {
    .client-selected {
      &_content {
        display: grid;
        grid-template-rows: auto auto auto;
        grid-row-gap: 1em;
      }
    }
  }
}

.sticky-top {
  position: sticky;
  top: 0;
}
