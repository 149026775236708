.content {
  width: 100%;
  display: flex;
  padding-right: 10px;
  overflow: auto;
  transition: 0.3s;
  background-color: white;
}

table thead:first-child td {
  border-left: none !important;
}

table thead tr th {
  background-color: white;
  text-transform: uppercase;
}

table thead tr th.orderned {
  color: #686868;
  background-color: #f8f8f8;
  font-weight: bold;
}

th {
  position: sticky;
  z-index: 1;
  top: 0;
  border: 1px solid lightgrey;
}

tbody tr {
  cursor: pointer;
  text-align: left;
}

tbody tr.locked td {
  padding-left: 0.4em;
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
}

.content.locked {
  background-color: #f4f4f4;
}

.locked table tbody tr {
  cursor: initial !important;
}

.locked table tbody tr:hover {
  background-color: transparent;
}

table tbody tr:hover td.locked {
  background-color: #e2e2e2 !important;
}

.locked table tr td {
  border-top: 1px solid lightgray !important;
  border-bottom: 1px solid lightgray !important;
}

.capitalize {
  text-transform: capitalize;
}

.firstUpper td::first-letter {
  text-transform: uppercase;
}

tbody .upperCase {
  text-transform: uppercase;
}

.loader-content {
  background-color: #f9f9f9;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.full_cell {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
