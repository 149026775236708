@import './constants.scss';
@import './styles/spacing.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html {
  font-size: $html_font_size;
}

html,
body {
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
}

a {
  text-decoration: none;
}

label.required:after {
  content: '*';
  color: red;
}

button {
  background-color: white;
  border: none;
}

button:focus,
button:hover {
  outline: none;
  cursor: pointer;
}

.list-transition {
  border-bottom: 1px solid black;
}

.list-transition-enter,
.list-transition-appear {
  /*We give the list the initial dimension of the list button*/
  max-height: 0px;
  overflow: hidden;
}

/* This is where we can add the transition*/
.list-transition-enter-active,
.list-transition-appear-active {
  border-bottom: 1px solid #cbcbcb;
  transition: all 400ms;
  overflow: hidden;
  max-height: 100vh;
}

.list-transition-enter-done {
  border-bottom: 1px solid #cbcbcb;
  overflow: hidden;
  max-height: 100vh;
}

/* This fires as soon as the this.state.showList is false */
.list-transition-exit {
  border-bottom: 1px solid #cbcbcb;
  overflow: hidden;
  max-height: 100vh;
}

/* fires as element leaves the DOM*/
.list-transition-exit-active {
  max-height: 0px;
  transition: all 400ms;
  overflow: hidden;
}

table {
  width: 100%;
  border-collapse: collapse;

  .material-icons {
    font-size: 1.2rem;
  }
}

thead th {
  border: 1px solid #c5c5c5;
  border-top: none;
  padding: 0.2em 0.4em;
  font-weight: lighter;
  text-align: left;
}

tbody {
  user-focus: true;
}

tbody tr:nth-child(2n) {
  background-color: #f5f5f5;
}

tbody tr:nth-child(2n) .unlockable,
tbody tr:nth-child(2n) .locked {
  background-color: #e9e9e9 !important;
}

tbody tr:hover,
tbody tr:focus {
  background-color: #e2e2e2;
}
tbody {
  font-weight: lighter;
  tr td {
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
  }
}

tbody tr.bordered td {
  border: 1px solid #c5c5c5;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #000000;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.left-transition-enter {
  max-width: 0px;
  transform: translateX(100%);
  background-color: white;
}

.left-transition-enter-done {
  border-left: 1px solid #cbcbcb;
  max-width: 600px;
  min-width: 500px;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  max-height: 100%;
  background-color: white;
  z-index: 99;
  transition: all 200ms ease-in;
}

.left-transition-exit {
  border-left: 1px solid #cbcbcb;
  width: 66vw;
  max-width: 600px;
  position: absolute;
  right: 0;
  height: 100%;
  background-color: white;
  z-index: 99;
}

/* fires as element leaves the DOM*/
.left-transition-exit-done {
  max-width: 0px;
  transform: translateX(100%);
  transition: all 400ms ease-in;
}

.align {
  display: inline-flex;
  width: 100%;
}

.b-white {
  background-color: white !important;
}

.b-hgrey {
  background-color: #393939;
}

.b-grey {
  background-color: #f5f5f5;
}

.b-lgrey {
  background-color: #f9f9f9;
}

.b-red {
  background-color: #c56060 !important;
}

.b-orange {
  background-color: #c58860 !important;
}

.b-yellow {
  background-color: #ffe882 !important;
}

.b-green {
  background-color: #568b6b;
}

.t-white {
  color: white;
}

.t-black {
  color: #000000;
}

.t-grey {
  color: #686868;
}

.t-lgrey {
  color: #c4c4c4;
}

.t-green {
  color: $valid !important;
}

.t-orange {
  color: $warning !important;
}

.t-red {
  color: $error !important;
}

.t-end {
  text-align: end;
}

@mixin brProps($position) {
  .br-#{$position} {
    border-#{$position}: 1px solid $light;
  }
}

@include brProps(top);
@include brProps(right);
@include brProps(bottom);
@include brProps(left);

.l-align {
  text-align: left !important;
}

.center-align {
  text-align: center !important;
}

.r-align {
  text-align: right !important;
}

.float-r {
  float: right;
}

.border-box {
  box-sizing: border-box;
}

@keyframes modalPop {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.modal {
  &_content {
    animation-name: modalPop;
    animation-duration: 0.2s;
  }
}

label {
  font-size: 0.8em;
  color: dimgrey;
}

input {
  font-size: 0.8em;
  border: none;
  padding: 0.2em;
  font-weight: inherit;
  color: inherit;

  &:focus {
    outline: none;
  }
}

input[type='number'] {
  -moz-appearance: textfield;
  width: 8em;
}

input[type='date'] {
  font-size: 0.8em;
  font-family: 'Open Sans', sans-serif;
  padding: 0 0.2em;
  font-weight: normal;
}

input[type='date']::-webkit-clear-button {
  display: none;
}

input[type='date']::-webkit-calendar-picker-indicator {
  font-size: 1.2em;
  color: #393939;
  background-color: transparent;
  cursor: pointer;
}

textarea {
  font-family: 'Open Sans', sans-serif;
  resize: none;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
}

textarea:disabled {
  background-color: transparent;
  border: none;
}

.clickable {
  cursor: pointer;
}

.pagination_panel button {
  padding: 0.2rem;
}

.pagination_panel button.selected {
  border: 1px solid lightgray;
}

i {
  font-size: 12px;
}

.t-xsmall {
  font-size: 0.4rem;
}

.t-small {
  font-size: 0.6rem;
}

.t-medium {
  font-size: 0.8rem;
}

.t-medium > i,
i.t-medium {
  font-size: 0.8rem;
}

.t-large,
.t-large input,
.t-large > i {
  font-size: 1.2rem !important;
}

.t-xlarge,
.t-xlarge > i {
  font-size: 1.6em !important;
}

.bold {
  font-weight: bold;
}

.light {
  font-weight: lighter;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
}

.column,
.row {
  display: flex;
}

.column {
  flex-direction: column;
  justify-content: center;
}

.row {
  align-items: center;
}

.wrap,
button.wrap,
.row.wrap,
.column.wrap {
  flex-wrap: wrap;
}

.row.gapped {
  gap: 5px !important;
}

.row.gapped-l {
  gap: 2rem !important;
}

.column.gapped > * {
  margin-bottom: 5px !important;
}

.column.center {
  align-items: center;
}

.row.center {
  justify-content: center;
}

.column.start {
  justify-content: flex-start;
}

.row.start {
  align-items: flex-start;
}

.column.end {
  align-items: flex-end;
}

.row.end {
  justify-content: flex-end;
}

.column.spaced,
.row.spaced {
  justify-content: space-between;
}

.row.flex-start {
  align-items: flex-start;
}

.row.flex-end {
  align-items: flex-end;
}

.p-small {
  padding: 0.2rem;
}

.p-medium {
  padding: 0.5rem;
}

.p-spaced {
  padding: 0.2rem 0.5rem;
}

.rounded {
  border-radius: 0.5rem;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
  min-height: 100%;
}

.fill-width {
  width: -webkit-fill-available !important;
}

.fill-height {
  height: -webkit-fill-available !important;
}

.fit-width {
  width: fit-content;
}

.fit-height {
  height: fit-content;
}

.scrollable-y {
  overflow-y: auto;
}

.text-selector-text {
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: normal;
  color: #686868;
}

.form-header {
  font-size: 1.2em;
  font-weight: lighter;
  display: block;
  padding: 0 5px 15px 0;
}

.form-title {
  font-size: 0.8em;
  font-weight: lighter;
  margin-bottom: -1px;
  padding: 3px 10px;
}

.form-content form {
  background-color: white;
}

.fitContent form {
  width: fit-content;
}

.dynamicGroup .dynamicGroup-text {
  font-size: 0.8em;
  color: dimgrey;
}

.dynamicGroup div,
.text-input.inline label {
  display: inline-block;
  margin-left: 10px;
}

form label,
.text-input label {
  display: block;
  font-size: 0.6em;
  padding-right: 10px;
}

form input,
form .text-input input {
  border: 1px solid transparent;
  border-bottom: 1px solid #cbcbcb !important;
  transition: 150ms ease-out;
  padding: 0.2em 0 0 2px;
}

.tlarge input:disabled {
  font-size: 0.8em;
}

.upperCase,
.upperCase input {
  text-transform: uppercase;
}

.capitalize,
.capitalize input {
  text-transform: capitalize;
}

.full,
.full input {
  width: 100%;
}

input::placeholder {
  font-weight: lighter;
  color: #d1d1d1;
  text-transform: capitalize;
}

form input:focus {
  outline: none;
  background-color: #f8f8f8;
  border: 1px solid #cbcbcb;
  border-radius: 3px;
}

input.onError {
  color: indianred;
}

form input.onError:focus {
  border-color: indianred !important;
}

form .dynamicGroup div {
  display: inline-block;
}

form .dynamicGroup input {
  min-width: 70px;
}

form .dynamicGroup .group {
  display: inline-block;
}

form .dynamicGroup .group input {
  background-color: transparent;
  border: none;
}

form .dynamicGroup .group > div {
  background-color: #cbcbcb;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 5px;
  padding: 1px 5px;
  margin-left: 0px;
  border-radius: 10px;
  font-weight: bold;
}

form input:disabled,
form select:disabled {
  background-color: transparent !important;
  border-color: transparent !important;
  padding-left: 5px;
}

form input:disabled {
  border-bottom: transparent !important;
}

input[type='checkbox'] {
  display: inline-block;
  width: 19px;
  cursor: pointer;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  transition: 150ms;
  padding: 0;
  font-family: inherit;
}

button.l-align {
  justify-content: flex-start;
}

.bordered {
  border: 1px solid #e8e8e8 !important;
}

.firstUpper::first-letter {
  text-transform: uppercase;
}

table.list {
  td,
  th {
    padding: 0.2rem 0.4rem;
  }

  tr {
    border-bottom: 1px solid #e5e5e5;
  }
}

button.addButton,
button.titleButton,
button.cancelButton,
button.returnButton {
  border-radius: 3px;
  padding: 0.2em 0.4em;
  background-color: transparent;
}

button.addButton {
  border: 1px solid #568b6b;
  color: #568b6b;
}

button.titleButton {
  color: #568b6b;
}

button.titleButton.active {
  color: #686868;
  background-color: #d5d5d5;
}

button.addButton.active,
button.addButton:hover,
button.addButton:focus {
  color: white;
  background-color: #568b6b;
}

button.addButton:disabled {
  background-color: #e2e2e2 !important;
  color: grey;
  border-color: transparent;
  cursor: not-allowed;
}

button.cancelButton.active,
button.cancelButton:hover,
button.cancelButton:focus {
  color: white;
  background-color: #c58860;
  border-color: #c58860;
}

button.returnButton {
  border: 1px solid lightgray;
  color: grey;
}

button.returnButton.active,
button.returnButton:hover,
button.returnButton:focus {
  color: dimgrey;
  background-color: #c5c5c5;
  border-color: #c5c5c5 !important;
}

.text-input .errorMessage {
  color: indianred;
}

select {
  background-color: white;
  font-weight: lighter !important;
}

select {
  padding: 0.2em 0.3em;
  font-size: 0.8em;
  font-weight: bolder;
  background-color: white;
}

select:disabled {
  border-color: transparent;
  font-size: 0.8em;
  padding: 5px 0px;
}

select:disabled::-ms-expand {
  display: none;
}

form select {
  min-width: 70px;
  width: 100%;
}

form select:focus {
  background-color: #f8f8f8;
}

form textarea {
  resize: none;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
}

form textarea:disabled {
  background-color: transparent;
  border: none;
}

form input[type='password']:enabled {
  border: 1px solid lightgray;
  padding: 0.4em 0.6em;
  border-radius: 15px;
  background-color: #f6f6f6;
  margin: 0.2em;
}

button .material-icons {
  font-size: 16px;
}

.material-icons {
  &.large {
    font-size: 2rem;
  }
}

.selectable {
  cursor: pointer;
}

.selectable:focus,
.selectable:hover {
  background-color: #e9e9e9 !important;
  color: #393939;
}

.selected {
  background-color: #e5e5e5;
}

.text-input-row input {
  width: 100%;
}

.text-input-row.selected {
  background-color: transparent;
}

.drop--button {
  padding: 0;
  background-color: transparent;
  border: none;
  width: fit-content !important;
  border-bottom: 1px solid transparent;
}

.drop--button:hover,
.drop--button:focus {
  outline: none;
  color: #568b6b;
  border-color: #568b6b;
  background-color: transparent !important;
}

.no-wrap {
  white-space: nowrap;
}

th.fit-content,
td.fit-content {
  width: 1px;
}

td.xsmall {
  width: 60px;
}
td.small {
  width: 140px;
  text-align: right;
}
td.medium {
  width: 200px;
}
td.large {
  width: 350px;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1em;
  width: 1em;
  border-radius: 400px;
  margin: 0.5em;
  border: 1px solid white;
}

.circle-button {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border-color: transparent;
  padding: 0;
  color: grey !important;
  cursor: pointer;
}

.green.circle-button:hover,
.green.circle-button:focus,
.green.circle-button.active {
  background-color: #568b6b;
  color: white !important;
  cursor: pointer;
}

.blue.circle-button:hover,
.blue.circle-button:focus,
.blue.circle-button.active {
  background-color: #4985c5;
  color: white !important;
  cursor: pointer;
}

.yellow.circle-button:hover,
.yellow.circle-button:focus,
.yellow.circle-button.active {
  background-color: #c5ae36;
  color: white !important;
  cursor: pointer;
}

@mixin alertProps($name, $color, $fontColor: white) {
  .alert-#{$name} {
    background-color: $color;
    color: $fontColor;
    border-color: $fontColor;
  }
}

@keyframes poping {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.alert-list {
  padding: 1em;
  bottom: 0;
  left: 0;
  z-index: 9999;

  & [class*='alert-'] {
    min-width: 200px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid;
    margin-top: 0.2em;
    margin-bottom: 0.2em;
    padding: 0.5em 1em;
    animation-name: poping;
    animation-duration: 0.5s;

    > *:not(:first-child) {
      margin-left: 0.4rem;
    }
  }

  @include alertProps(valid, $valid);
  @include alertProps(error, $error);
  @include alertProps(info, white, $heavy);
}

@mixin buttonProps($name, $color, $fontColor: white) {
  .button-#{$name} {
    @if ($fontColor == white) {
      color: $color;
    } @else {
      color: $fontColor;
    }

    &.grey {
      color: #959595;
    }

    &.bordered {
      border-color: $color !important;
    }

    &.rounded {
      border-radius: 4rem;
    }

    &:not(.transparent) {
      &.selected,
      &.active,
      &:hover:not(:disabled),
      &:focus {
        background-color: $color;
        color: $fontColor !important;
      }
    }

    &.transparent {
      &.selected,
      &.active,
      &:focus,
      &:hover:not(:disabled) {
        color: $color !important;
        border-color: $color !important;
      }
    }
  }
}

[class*='button-'] {
  border-radius: 5px;
  border: 1px solid transparent;
  padding: 0.3rem;
  text-align: center;
  display: flex;
  align-items: center;
  transition: 0.2s;
  background-color: transparent;

  > *:not(:first-child) {
    margin-left: 0.4rem;
  }

  &.circle {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    padding: 0;
    margin: 0;
  }

  &:disabled {
    background-color: #efefef;
    border-color: transparent;
    color: grey;
    cursor: auto;
  }

  &.minified {
    border-radius: 50%;
    padding: 1px;
  }
}

@include buttonProps(validate, $valid);
@include buttonProps(alert, $error);
@include buttonProps(warning, $warning);
@include buttonProps(edit, #3b8457);
@include buttonProps(print, #aaaaaa);
@include buttonProps(send, #316297);
@include buttonProps(onWait, #e89c48);
@include buttonProps(lgrey, #f9f9f9, grey);
@include buttonProps(grey, grey);
@include buttonProps(white, white, grey);

.deleteButton {
  background-color: transparent;
  border: none;
  padding: 0;
  color: grey;
}

.deleteButton:hover,
.deleteButton:focus {
  color: indianred !important;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.light-overlay {
  background-color: rgba(0, 0, 0, 0.1);
}

.card {
  padding: 0.6em;
  border: 1px solid #e8e8e8;
  background-color: white;
  border-radius: 10px;
}
