.prices-list.fixed {
  table-layout: fixed;
}

.prices-list tr:focus {
  outline: none;
}

.prices-list input {
  width: 100%;
  background-color: transparent;
  font-size: 1em;
  padding: 0 !important;
}

.prices-list input:hover,
.prices-list input:focus {
  width: 100%;
  background-color: transparent;
  outline: none;
}

.prices-list th.fitContent,
.prices-list td.fitContent {
  width: 1px;
}

.prices-list tr.selected {
  background-color: #d5d5d5 !important;
}

.prices-list tr.selected input {
  background-color: transparent !important;
}
