.pointRow {
  display: flex;
  align-items: center;
  height: 1.5rem;
  font-weight: lighter;
  border: 1px solid #cbcbcb;
  border-radius: 10px;

  .materialIcons {
    font-size: 1.2rem;
  }

  .pointEventAmount {
    font-weight: bold;
    &.positive {
      color: #568b6b;
      &:before {
        content: '+';
      }
    }
    &.negative {
      color: #c56060;
    }
  }

  .otherAmount {
    font-weight: bold;
  }

  .clearIcon {
    font-size: 1.5rem;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}
