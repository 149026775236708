.order-card {
  display: grid;
  grid-template-rows: 0fr auto;
  height: 100%;

  &_header {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 0.5em;
    padding: 0.4em 0.8em;
    border-bottom: 1px solid lightgrey;

    .order-info {
      display: grid;
      grid-column-gap: 0.5em;
      grid-row-gap: 0.5em;
      box-sizing: content-box;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    .card {
      border-radius: 0.5rem;
      border: 1px solid #e8e8e8;
      background-color: white;
      padding: 0.2rem;
    }
  }

  &_content {
    min-height: 100%;
  }
}

.order-info .detail-card {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
}

.order-card-header > div {
  padding: 0.2em;
}

.order-card--card {
  border: 1px solid #c5c5c5;
  padding: 0.6em 0.8em;
  border-radius: 0.4em;
  color: #393939;
}

/* The Masonry Container */
.masonry {
  display: grid;
  margin: 1.5em auto;
  max-width: 95%;
  column-gap: 1em;
  row-gap: 1em;

  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));

  > * {
    background: #fff;
    padding: 0.3rem;
  }
}
