@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap');

.App-link {
  color: #09d3ac;
}

.App {
  font-family: 'Open Sans', sans-serif;
  width: 100% !important;
  height: 100% !important;
}

.page {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

nav {
  .material-icons {
    font-size: 16px;
  }
}

.crud-header {
  border-bottom: 1px solid #cbcbcb;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 0.3em;

  .title {
    font-size: 1em;
    padding: 0 15px;
    color: #568b6b;
    display: flex;
    align-items: center;

    > *:not(:first-child) {
      margin-left: 0.4rem;
    }
  }

  .search-bar > i {
    font-size: 1.4em;
  }

  .search-bar {
    color: #686868;
    transition: 0.3s;
    &:focus-within {
      background-color: #e4e4e4;
    }
    input {
      font-size: 1.2rem;
      font-weight: 300;
      border: none;
      background-color: transparent;
      &:focus {
        outline: none;
      }

      &::placeholder {
        font-size: 0.8rem;
      }
    }
  }
}

.search-bar > i {
  font-size: 1.4em;
}

.search-bar {
  color: #686868;
  transition: 0.3s;
  &:focus-within {
    background-color: #e4e4e4;
  }
  input {
    font-size: 0.8rem;
    font-weight: 300;
    border: none;
    background-color: transparent;
    &:focus {
      outline: none;
    }

    &::placeholder {
      font-size: 0.8rem;
    }
  }
}

.cancelButton {
  padding: 5px;
  border: 1px solid #cbcbcb;
  background-color: transparent;
  color: grey;
  border-radius: 3px;
  transition: 150ms;
}

::-webkit-scrollbar {
  width: 0.8em;
  height: 0.5em;
  background-color: rgba(255, 255, 255, 0);
}

::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: white;
}

::-webkit-scrollbar-thumb {
  background-color: #568b6b;
  padding: 5px;
  border-radius: 10px;
  border: 3px solid transparent;
  background-clip: padding-box;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb:hover {
  background-color: grey;
}
