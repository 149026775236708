.practitioner-form,
.client-form {
  form {
    padding: 0;
    border: none;
    .text-selector,
    .text-input {
      border-bottom: 1px solid #d7d7d7;
      padding: 0.2rem;
    }

    .form-section {
      margin-bottom: 0.5rem;
      &:focus-within {
      }

      &_label {
        color: #568b6b;
        padding: 0.2rem;
        border: 1px solid transparent;
        border-radius: 7px;
        & > * {
          margin-right: 0.2rem;
        }
      }

      &_content {
        padding-left: 1rem;
        &:first-child {
          padding-left: 0;
        }
      }
    }

    .text-selector {
      &:focus-within {
        background-color: #f9f9f9;
      }

      .text-selector-text,
      select {
        border: none;
        font-size: 0.8rem;
        padding: 0;
      }
    }

    .text-input {
      .material-icons {
        font-size: 0.8rem;
        color: #949494;
      }

      &.suffix .text-input_row {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-column-gap: 0.2rem;
      }

      &:focus-within {
        background-color: #f9f9f9;
        .text-input_suffix {
          span {
            color: #65a380;
          }
        }
      }

      .text-input_suffix {
        min-width: 1rem;
      }

      label {
        font-size: 0.6rem;
        font-weight: lighter;
      }
      input {
        color: #686868;
        border: none !important;
        font-size: 1rem;
        background-color: transparent;
        &::placeholder {
          font-size: 0.6rem;
          font-weight: 300;
        }
      }
    }
  }
}

.address-tab {
}

.address-tab_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #c4c4c4;

  .tab {
    font-size: 0.8em;
    padding: 0.2em 0.6em;
    margin: 2px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid #cbcbcb;
    transition: 0.5s;

    &.add,
    &.active {
      background-color: #568b6b;
      border-color: #568b6b;
      color: white;
    }

    &.newAddress-button {
      &:hover,
      &:focus {
        border-radius: 50px;
        border: transparent;
      }
    }

    &:hover,
    &:focus {
      background-color: #cbcbcb;
      cursor: pointer;
    }
  }
}

.address-tab_header .tab.active {
  background-color: #568b6b;
  border-color: #568b6b;
  color: white;
}

.tab i {
  font-size: 14px;
}

.addressTab--body {
  padding: 10px;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
}

.address-form {
  &_lines {
    & > :first-child {
      & input:disabled {
        font-weight: bold;
      }
    }

    & > :not(:first-child) {
      padding-left: 0.5rem;
    }
  }
}

.address-detail {
  &_line {
    &:first-child {
      font-size: 1.2rem;
      text-transform: uppercase;
    }
    &:not(:first-child) {
      font-weight: lighter;
      text-transform: capitalize;
      color: #777777;
    }
  }
}
