.header {
  border-bottom: 1px solid #d7d7d7;
  box-sizing: border-box;
  padding-top: 0.4rem;
  overflow-x: hidden;
}

.header img.logo {
  height: 100%;
}

.panelButton {
  display: flex;
  align-items: center;

  .add-button {
    padding: 5px 15px;
    text-decoration: none !important;
    text-underline: none !important;
    background-color: white;
    border: none;
    transition: 0.2s;
    color: #568b6b;

    &:hover,
    &:focus {
      background-color: #568b6b;
      color: white;
    }
  }

  .button {
    display: flex;
    align-items: center;
    padding: 0.4rem 0.6rem;
    white-space: nowrap;
    text-decoration: none !important;
    text-underline: none !important;
    transition: 0.2s;
    color: #959595;

    .material-icons {
      font-size: 1.4rem;
    }

    & *:not(:first-child) {
      margin-left: 0.4rem;
    }

    &:focus,
    &:hover {
      outline: none;
      text-decoration: underline #568b6b;
      background-color: #f8f8f8;
      color: #568b6b;
    }

    &.active {
      color: #568b6b;
      font-weight: bold;
    }
  }
}

.header .logout_button {
  background-color: transparent;
  border: none;
  color: #686868;
  font-size: 1em;
  font-weight: lighter;
  padding: 5px;
  border-radius: 3px;
  margin-right: 10px;
}

.header .logout_button:hover {
  background-color: #c58860;
  color: white;
}
