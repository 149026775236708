.aligned {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login_button {
  width: 100%;
  text-align: center !important;
  background-color: white;
  color: #568b6b;
  border: 1px solid #568b6b;
  border-radius: 3px;
  padding: 5px;
  margin-top: 10px;
  transition: 0.3s;
}

.login-form {
  input:enabled {
    border: 1px solid lightgray;
    padding: 0.4em 0.6em;
    border-radius: 15px;
    background-color: #f6f6f6;
    margin: 0.2em;
  }
}

.login_button:hover,
.login_button:focus {
  background-color: #568b6b;
  color: #f8f8f8;
}

.login_button:disabled {
  background-color: #f8f8f8;
  border-color: #cbcbcb;
  color: #cbcbcb;
}

.errorMessage {
  color: indianred;
  font-size: 12px;
}
