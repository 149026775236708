.events-panel {
  table {
    border-spacing: 0 0.4rem;
    border-collapse: separate !important;
    tr {
      background-color: transparent !important;
      border: 1px solid grey;
      td {
        border: none !important;
        padding: 0.4rem;
        border-bottom: 1px solid #e4e4e4 !important;
        border-top: 1px solid #e4e4e4 !important;

        &:first-child {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          border-left: 1px solid #e4e4e4 !important;
        }

        &:last-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          border-right: 1px solid #e4e4e4 !important;
        }
      }
    }
  }
}
